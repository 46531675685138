import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getVideo,
  getVideos,
  createVideo,
  updateVideo,
  deleteVideo,
  publishVideo,
  GetVideoRequestOptions,
  CreateVideoRequestOptions,
  UpdateVideoRequestOptions,
  DeleteVideoRequestOptions,
  PublishVideoRequestOptions,
  VideoResponse
} from '@/services/api';
import { UseVideosOptions } from './interfaces';

export function useVideo(options: GetVideoRequestOptions, useOptions: Omit<UseQueryOptions<VideoResponse, unknown, VideoResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('video', () => getVideo(options), useOptions);
}

// eslint-disable-next-line @typescript-eslint/camelcase
export function useVideos({ page, status, keyword, is_free }: UseVideosOptions = {}) {
  return useQuery(
    // eslint-disable-next-line @typescript-eslint/camelcase
    reactive(['videos', { page, status, keyword, is_free }]),
    // eslint-disable-next-line @typescript-eslint/camelcase
    () => getVideos({ query: { page: unref(page), status: unref(status), keyword: unref(keyword), is_free: unref(is_free) } }),
    { keepPreviousData: true }
  );
}

export function useCreateVideo() {
  return useMutation((options: CreateVideoRequestOptions) => createVideo(options));
}

export function useUpdateVideo() {
  return useMutation((options: UpdateVideoRequestOptions) => updateVideo(options));
}

export function useDeleteVideo() {
  return useMutation((options: DeleteVideoRequestOptions) => deleteVideo(options));
}

export function usePublishVideo() {
  return useMutation((options: PublishVideoRequestOptions) => publishVideo(options));
}
