import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getIPWhitelist,
  getIPWhitelists,
  createIPAdress,
  updateIPAddress,
  deleteIPAddress,
  GetIPWhitelistRequestOptions,
  CreateIPAdressRequestOptions,
  UpdateIPAddressRequestOptions,
  DeleteIPAddressRequestOptions,
  IPWhitelistResponse
} from '@/services/api';
import { UseIPWhitelistsOptions } from './interfaces';

export function useWhitelist(options: GetIPWhitelistRequestOptions, useOptions: Omit<UseQueryOptions<IPWhitelistResponse, unknown, IPWhitelistResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('whitelist', () => getIPWhitelist(options), useOptions);
}

export function useWhitelists({ page, keyword }: UseIPWhitelistsOptions = {}) {
  return useQuery(
    reactive(['whitelists', { page, keyword }]),
    () => getIPWhitelists({ query: { page: unref(page), keyword: unref(keyword) } }),
    { keepPreviousData: true }
  );
}

export function useCreateIPAddress() {
  return useMutation((options: CreateIPAdressRequestOptions) => createIPAdress(options));
}

export function useUpdateIPAddress() {
  return useMutation((options: UpdateIPAddressRequestOptions) => updateIPAddress(options));
}

export function useDeleteIPAddress() {
  return useMutation((options: DeleteIPAddressRequestOptions) => deleteIPAddress(options));
}
