import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getPlan,
  getPlans,
  createPlan,
  updatePlan,
  GetPlanRequestOptions,
  CreatePlanRequestOptions,
  UpdatePlanRequestOptions,
  PlanResponse
} from '@/services/api';
import { UsePlansOptions } from './interfaces';

export function usePlan(options: GetPlanRequestOptions, useOptions: Omit<UseQueryOptions<PlanResponse, unknown, PlanResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('plan', () => getPlan(options), useOptions);
}

// eslint-disable-next-line @typescript-eslint/camelcase
export function usePlans({ page, show, is_best, keyword }: UsePlansOptions = {}) {
  return useQuery(
    // eslint-disable-next-line @typescript-eslint/camelcase
    reactive(['plans', { page, show, is_best, keyword }]),
    // eslint-disable-next-line @typescript-eslint/camelcase
    () => getPlans({ query: { page: unref(page), show: unref(show), is_best: unref(is_best), keyword: unref(keyword) } }),
    { keepPreviousData: true }
  );
}

export function useCreatePlan() {
  return useMutation((options: CreatePlanRequestOptions) => createPlan(options));
}

export function useUpdatePlan() {
  return useMutation((options: UpdatePlanRequestOptions) => updatePlan(options));
}
