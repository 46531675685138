import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getSegpays,
  DeleteSegpayRequestOptions,
  deleteSegpay,
  SegpayResponse,
  CreateSegpayRequestOptions,
  createSegpay,
  getSegpay,
  GetSegpayRequestOptions,
  updateSegpay,
  UpdateSegpayRequestOptions
} from '@/services/api';
import { UseSegpayOptions } from './interfaces';

export function useSegpay(options: GetSegpayRequestOptions, useOptions: Omit<UseQueryOptions<SegpayResponse, unknown, SegpayResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('segpay', () => getSegpay(options), useOptions);
}

export function useSegpays({ page, date }: UseSegpayOptions = {}) {
  return useQuery(
    reactive(['Segpays', { page, date }]),
    () => getSegpays({
      query: {
        page: unref(page),
        ...(unref(date)[0] && {
          // eslint-disable-next-line @typescript-eslint/camelcase
          start_date: `${unref(date)[0]}`
        }),
        ...(unref(date)[1] && {
          // eslint-disable-next-line @typescript-eslint/camelcase
          end_date: `${unref(date)[1]}`
        })
      }
    }),
    { keepPreviousData: true }
  );
}

export function useCreateSegpay() {
  return useMutation((options: CreateSegpayRequestOptions) => createSegpay(options));
}

export function useUpdateSegpay() {
  return useMutation((options: UpdateSegpayRequestOptions) => updateSegpay(options));
}

export function useDeleteSegpay() {
  return useMutation((options: DeleteSegpayRequestOptions) => deleteSegpay(options));
}
