import { reactive, unref } from 'vue';
import { useQuery, useMutation } from 'vue-query';

import {
  getComments,
  deleteComment,
  DeleteCommentRequestOptions,
  EnableCommentRequestOptions,
  enableComment,
  DisableCommentRequestOptions,
  disableComment,
  getCommentsReport,
  BanCommentRequestOptions,
  banComment,
  RejectReportCommentRequestOptions,
  rejectReportComment
} from '@/services/api';
import { UseCommentsOptions } from './interfaces';

export function useComments({ page, keyword, status }: UseCommentsOptions = {}) {
  return useQuery(
    reactive(['comments', { page, keyword, status }]),
    () => getComments({ query: { page: unref(page), keyword: unref(keyword), status: unref(status) } }),
    { keepPreviousData: true }
  );
}

export function useDeleteComment() {
  return useMutation((options: DeleteCommentRequestOptions) => deleteComment(options));
}

export function useEnableComment() {
  return useMutation((options: EnableCommentRequestOptions) => enableComment(options));
}

export function useDisableComment() {
  return useMutation((options: DisableCommentRequestOptions) => disableComment(options));
}

export function useCommentsReports({ page, keyword }: UseCommentsOptions = {}) {
  return useQuery(
    reactive(['comments', { page, keyword }]),
    () => getCommentsReport({ query: { page: unref(page), keyword: unref(keyword) } }),
    { keepPreviousData: true }
  );
}

export function useBanComment() {
  return useMutation((options: BanCommentRequestOptions) => banComment(options));
}

export function useRejectReportComment() {
  return useMutation((options: RejectReportCommentRequestOptions) => rejectReportComment(options));
}
