import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getUser,
  getUsers,
  updateUser,
  GetUserRequestOptions,
  UpdateUserRequestOptions,
  DisableUserRequestOptions,
  disableUser,
  EnableUserRequestOptions,
  enableUser,
  SendEmailUserRequestOptions,
  sendEmailUser,
  SendResetUserRequestOptions,
  sendResetUser,
  UserResponse,
  BatchCreateUserRequestOptions,
  batchCreateUser
} from '@/services/api';
import { UseUsersOptions } from './interfaces';

export function useUser(options: GetUserRequestOptions, useOptions: Omit<UseQueryOptions<UserResponse, unknown, UserResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('user', () => getUser(options), useOptions);
}

export function useUsers({ page, keyword, status }: UseUsersOptions = {}) {
  return useQuery(
    reactive(['users', { page, keyword, status }]),
    () => getUsers({ query: { page: unref(page), keyword: unref(keyword), status: unref(status) } }),
    { keepPreviousData: true }
  );
}

export function useBatchCreateUser() {
  return useMutation((options: BatchCreateUserRequestOptions) => batchCreateUser(options));
}

export function useUpdateUser() {
  return useMutation((options: UpdateUserRequestOptions) => updateUser(options));
}

export function useDisableUser() {
  return useMutation((options: DisableUserRequestOptions) => disableUser(options));
}

export function useEnableUser() {
  return useMutation((options: EnableUserRequestOptions) => enableUser(options));
}

export function useSendEmailUser() {
  return useMutation((options: SendEmailUserRequestOptions) => sendEmailUser(options));
}

export function useSendResetUser() {
  return useMutation((options: SendResetUserRequestOptions) => sendResetUser(options));
}
