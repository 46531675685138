import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getChannel,
  getChannels,
  createChannel,
  updateChannel,
  GetChannelRequestOptions,
  CreateChannelRequestOptions,
  UpdateChannelRequestOptions,
  DeleteChannelRequestOptions,
  deleteChannel,
  GetChannelCampaignRequestOptions,
  CreateChannelCampaignRequestOptions,
  createChannelCampaign,
  UpdateChannelCampaignRequestOptions,
  updateChannelCampaign,
  DeleteChannelCampaignRequestOptions,
  deleteChannelCampaign,
  getChannelCampaign,
  getChannelCampaigns,
  GetChannelCampaignsRequestOptions,
  ChannelResponse
} from '@/services/api';
import { UseChannelsOptions } from './interfaces';

export function useChannel(options: GetChannelRequestOptions, useOptions: Omit<UseQueryOptions<ChannelResponse, unknown, ChannelResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('channel', () => getChannel(options), useOptions);
}

export function useChannels({ page, type }: UseChannelsOptions = {}) {
  return useQuery(
    reactive(['channels', { page, type }]),
    () => getChannels({ query: { page: unref(page), type: unref(type) } }),
    { keepPreviousData: true }
  );
}

export function useCreateChannel() {
  return useMutation((options: CreateChannelRequestOptions) => createChannel(options));
}

export function useUpdateChannel() {
  return useMutation((options: UpdateChannelRequestOptions) => updateChannel(options));
}

export function useDeleteChannel() {
  return useMutation((options: DeleteChannelRequestOptions) => deleteChannel(options));
}

export function useChannelCampaign(options: GetChannelCampaignRequestOptions) {
  return useQuery('channel', () => getChannelCampaign(options));
}

export function useChannelCampaigns(options: GetChannelCampaignsRequestOptions) {
  return useQuery('channel', () => getChannelCampaigns(options));
}

export function useCreateChannelCampaign() {
  return useMutation((options: CreateChannelCampaignRequestOptions) => createChannelCampaign(options));
}

export function useUpdateChannelCampaign() {
  return useMutation((options: UpdateChannelCampaignRequestOptions) => updateChannelCampaign(options));
}

export function useDeleteChannelCampaign() {
  return useMutation((options: DeleteChannelCampaignRequestOptions) => deleteChannelCampaign(options));
}
