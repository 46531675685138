import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getQuestion,
  getQuestions,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  GetQuestionRequestOptions,
  CreateQuestionRequestOptions,
  UpdateQuestionRequestOptions,
  DeleteQuestionRequestOptions,
  QuestionResponse
} from '@/services/api';
import { UseQuestionsOptions } from './interfaces';

export function useQuestion(options: GetQuestionRequestOptions, useOptions: Omit<UseQueryOptions<QuestionResponse, unknown, QuestionResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('question', () => getQuestion(options), useOptions);
}

export function useQuestions({ page, keyword }: UseQuestionsOptions = {}) {
  return useQuery(
    reactive(['questions', { page, keyword }]),
    () => getQuestions({ query: { page: unref(page), keyword: unref(keyword) } }),
    { keepPreviousData: true }
  );
}

export function useCreateQuestion() {
  return useMutation((options: CreateQuestionRequestOptions) => createQuestion(options));
}

export function useUpdateQuestion() {
  return useMutation((options: UpdateQuestionRequestOptions) => updateQuestion(options));
}

export function useDeleteQuestion() {
  return useMutation((options: DeleteQuestionRequestOptions) => deleteQuestion(options));
}
