import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getPromotion,
  getPromotions,
  createPromotion,
  updatePromotion,
  GetPromotionRequestOptions,
  CreatePromotionRequestOptions,
  UpdatePromotionRequestOptions,
  DeletePromotionRequestOptions,
  deletePromotion,
  updatePromotionPlans,
  UpdatePromotionPlansRequestOptions,
  PromotionResponse
} from '@/services/api';
import { UsePromotionsOptions } from './interfaces';

export function usePromotion(options: GetPromotionRequestOptions, useOptions: Omit<UseQueryOptions<PromotionResponse, unknown, PromotionResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('promotion', () => getPromotion(options), useOptions);
}

export function usePromotions({ page, keyword, status }: UsePromotionsOptions = {}) {
  return useQuery(
    reactive(['promotions', { page, keyword, status }]),
    () => getPromotions({ query: { page: unref(page), keyword: unref(keyword), status: unref(status) } }),
    { keepPreviousData: true }
  );
}

export function useCreatePromotion() {
  return useMutation((options: CreatePromotionRequestOptions) => createPromotion(options));
}

export function useUpdatePromotion() {
  return useMutation((options: UpdatePromotionRequestOptions) => updatePromotion(options));
}

export function useDeletePromotion() {
  return useMutation((options: DeletePromotionRequestOptions) => deletePromotion(options));
}

export function useSavePromotionPlans() {
  return useMutation((options: UpdatePromotionPlansRequestOptions) => updatePromotionPlans(options));
}
