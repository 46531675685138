import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';

import {
  getDirector,
  getDirectors,
  createDirector,
  updateDirector,
  deleteDirector,
  GetDirectorRequestOptions,
  CreateDirectorRequestOptions,
  UpdateDirectorRequestOptions,
  DeleteDirectorRequestOptions,
  ResetDirectorRequestOptions,
  DisableDirectorRequestOptions,
  EnableDirectorRequestOptions,
  DirectorResponse
} from '@/services/api';
import { UseDirectorsOptions } from './interfaces';

export function useDirector(options: GetDirectorRequestOptions, useOptions: Omit<UseQueryOptions<DirectorResponse, unknown, DirectorResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('admin', () => getDirector(options), useOptions);
}

export function useDirectors({ page, keyword }: UseDirectorsOptions = {}) {
  return useQuery(
    reactive(['admins', { page, keyword }]),
    () => getDirectors({ query: { page: unref(page), keyword: unref(keyword) } }),
    { keepPreviousData: true }
  );
}

export function useCreateDirector() {
  return useMutation((options: CreateDirectorRequestOptions) => createDirector(options));
}

export function useUpdateDirector() {
  return useMutation((options: UpdateDirectorRequestOptions) => updateDirector(options));
}

export function useDeleteDirector() {
  return useMutation((options: DeleteDirectorRequestOptions) => deleteDirector(options));
}
